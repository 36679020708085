


























import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { PageNum } from "@/tool/interface-index";
@Component({
  name: "pagination",
})
export default class extends Vue {
  @Prop({ default: { curPage: 1 } }) private pageNum!: PageNum;
  private showPagination: boolean = false;

  handleSizeChange(val: any) {
    this.$emit("upDataPage");
  }
  handleCurrentChange(val: any) {
    this.$emit("upDataPage");
  }

  mounted() {
    this.showPagination = window.innerWidth < 800 ? false : true; // 屏幕宽度小于800显示简易版分页
  }
}
