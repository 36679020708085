

































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import {
  getTestPaperGroupList,
  getTestPaperList,
  getTestPaperGroupGradeOptionList,
} from "@/api/testPaper";
import { TestPaperGroup, TestPaperGroupGradeOption } from "@/tool/_class";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "TestPaperPackageListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private query: {
    gradeNo?: string;
    grade?: string;
    termNo?: string;
    term?: string;
    termNo02?: string;
    term02?: string;
  } = {};

  private term02s: {}[] = [
    { label: "上册", value: "上册" },
    { label: "下册", value: "下册" },
    { label: "全册", value: "全册" },
  ];

  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private grades: TestPaperGroupGradeOption[] = [];
  private testPaperGroups: TestPaperGroup[] = [];

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  private handleTerm02Change(value: string) { }

  private doUseAuthorizeClick(testPaperId: string) {
    this.$router.push({
      path: "testPaperUseAuth",
      query: {
        testPaperIds: [testPaperId],
        useAuthTo: "right",
      },
    });
  }

  private standardAnswerFormatter(row: any, column: any) {
    if (row.questionCount) {
      return `${row.standardAnswerCount} / ${row.questionCount}`;
    } else {
      return "0";
    }
  }

  private queryTestPaperClick() {
    this.pageNum.curPage = 1;
    this.refPaperGroupList();
  }
  private upDataPage() {
    this.refPaperGroupList();
  }
  private loading: boolean = false;
  private async refPaperGroupList() {
    //演示要屏蔽年级在此处理
    try {
      this.loading = true;
      const res = await getTestPaperGroupList(
        _.merge(
          {
            curPage: this.pageNum.curPage,
            pageSize: this.pageNum.pageSize,
          },
          this.query
        )
      );
      this.testPaperGroups = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
      this.pageNum.curPage = res.data.curPage;
      this.pageNum.pageSize = res.data.pageSize;
    } finally {
      this.loading = false;
    }
  }

  async doUseAuthToClick(group: TestPaperGroup, useAuthTo: string) {
    const { data } = await getTestPaperList({
      gradeNo: group.gradeNo,
      term: group.term,
      keyWords: "",
      curPage: 1,
      pageSize: 9999,
    });

    let testPaperIds: string[] = [];
    for (let i = 0; i < data.items.length; i++) {
      testPaperIds.push((data.items[i] as any).testPaperId as string);
    }
    console.log(`testPaperIds:${JSON.stringify(testPaperIds)}`);
    this.$router.push({
      path: "testPaperUseAuth",
      query: {
        testPaperIds: testPaperIds,
        useAuthTo: useAuthTo,
      },
    });
  }

  async doGotoTestPaperListPageClick(group: TestPaperGroup) {
    const { data } = await getTestPaperList({
      gradeNo: group.gradeNo,
      term: group.term,
      keyWords: "",
      curPage: 1,
      pageSize: group.testPaperCount,
    });

    let testPaperIds: string[] = [];
    for (let i = 0; i < data.items.length; i++) {
      testPaperIds.push((data.items[i] as any).testPaperId as string);
    }
    while (testPaperIds.length <= 1) {
      testPaperIds.push('');
    }
    console.log(`testPaperIds:${JSON.stringify(testPaperIds)}`);
    this.$router.push({
      path: "testList",
      query: {
        testPaperIds: testPaperIds,
      },
    });
  }

  private async __init() {
    this.grades.splice(0, this.grades.length);
    const { data } = await getTestPaperGroupGradeOptionList();
    data.items.forEach((element: TestPaperGroupGradeOption) => {
      //演示要屏蔽年级在此处理
      this.grades.push(element);
    });

    console.log(`grades: ${JSON.stringify(this.grades)}`);
  }

  activated() {
    this.__init();
    this.refPaperGroupList();
  }
  mounted() { }
}
